import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";
import TeamData from "../../api/Team";

const TeamSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const { SectionTitleShow = false, sliceStart = 0, sliceEnd = 3 } = props;

  return (
    <section className={"" + props.hclass}>
      <div className="container">
        {SectionTitleShow && (
          <div className="row">
            <SectionTitle
              subtitle={"Our Team"}
              title={"Our Expert Team"}
              titleColor={"Member"}
            />
          </div>
        )}
        <div className="wpo-team-wrap">
          <div className="row">
            {TeamData.map((team, index) => (
              <div className="col col-lg-3 col-sm-6 col-12" key={index}>
                <div className="wpo-team-item ">
                  <div className="wpo-team-img">
                    <img src={team.Imga} alt="" />
                  </div>
                  <div className="wpo-team-text">
                    <h3>
                      <Link
                        onClick={ClickHandler}
                        to={`/team-single/${team.slug}`}
                      >
                        {team.title}
                      </Link>
                    </h3>
                    <span>{team.subtitle}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
