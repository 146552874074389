import React from "react";

const SkillSection = (props) => {
  return (
    <section className={"" + props.hclass}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="wpo-skill-img">
              <img src={props.Akimg} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12">
            <div className="wpo-section-title-s2">
              <p>Our Skill</p>
              <h2>
                Why choose <span>us?</span>{" "}
              </h2>
            </div>
            <div className="wpo-skill-progress">
              <div className="wpo-progress-single">
                <h5 className="progress-title">
                  Expertise - decades of industry experience with deep market
                  knowledge.
                </h5>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "90%" }}></div>
                </div>
              </div>
              <div className="wpo-progress-single">
                <h5 className="progress-title">
                  Quality Assurance - rigorous quality control to ensure top
                  standards.
                </h5>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "100%" }}></div>
                </div>
              </div>
              <div className="wpo-progress-single">
                <h5 className="progress-title">
                  Efficient Logistics - advanced logistics network for timely
                  and reliable delivery.
                </h5>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "95%" }}></div>
                </div>
              </div>
              <div className="wpo-progress-single">
                <h5 className="progress-title">
                  Global Reach - truthfull partnerships and capabilities across
                  diverse regions.
                </h5>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "80%" }}></div>
                </div>
              </div>
            </div>
            {/* <FunFactSection /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SkillSection;
