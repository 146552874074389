import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const Product = ({ item }) => {
  if (!item) {
    return <div>Loading...</div>; 
  }

  const { singImg, title, subTitle, description } = item;

  return (
    <div className="row">
      <div className="col col-lg-5 col-12">
        <div className="shop-single-slider">
          <div className="slider-nav">
            <div>
              {singImg && (
                <Zoom>
                  <img src={singImg} alt="products" />
                </Zoom>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col col-lg-7 col-12">
        <div className="product-details">
          <h2>{title}</h2>
          <div className="product-rt">
            <div className="rating">
              <i className="flaticon-star"></i>
              <i className="flaticon-star"></i>
              <i className="flaticon-star"></i>
              <i className="flaticon-star"></i>
              <i className="flaticon-star-1"></i>
            </div>
          </div>
          <p>{subTitle}</p>
          <ul>
            {Array.isArray(description) ? (
              description.map((d, index) => (
                <li key={index}>{d}</li>
              ))
            ) : (
              <li>No description available</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Product;
