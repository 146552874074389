import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "../ModalVideo/VideoModal";
import { Navigation, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import hero5 from "../../images/slider/4.png";
import hero6 from "../../images/slider/2.png";
import hero7 from "../../images/slider/3.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const Hero4 = () => {
  return (
    <section className="wpo-box-stylАe">
      <div className="wpo-hero-slider wpo-hero-slider-s3">
        <Swiper
          modules={[Navigation, A11y, Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1800}
          parallax={true}
          navigation
          pagination={true}
        >
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${hero5})` }}
            >
              <div className="container-fluid">
                <div className="slide-content">
                  <div className="slide-title">
                    <h2>
                      Your Partner in <span>International</span> Trade
                    </h2>
                  </div>
                  <div className="slide-text">
                    <p>
                      Bringing the world's <span>Best Quality</span> poultry to
                      your Table
                    </p>
                  </div>
                  <div className="clearfix"></div>
                  <div className="slide-btns">
                    <ul>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          className="theme-btn-s2"
                          to="/contact"
                        >
                          Get a Free Quote
                        </Link>
                      </li>
                      <li className="video-holder">
                        <VideoModal />
                      </li>
                      <li className="video-text">Watch Our Video</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${hero6})` }}
            >
              <div className="container-fluid">
                <div className="slide-content">
                  <div className="slide-title">
                    <h2>From Top Feed to Top Birds: Quality from the Start</h2>
                  </div>
                  <div className="slide-text">
                    <p>
                      High-nutrition, organic feed for optimal growth. Committed
                      to excellence for strong, vibrant poultry.
                    </p>
                  </div>
                  <div className="clearfix"></div>
                  <div className="slide-btns">
                    <ul>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          className="theme-btn-s2"
                          to="/contact"
                        >
                          Get a Free Quote
                        </Link>
                      </li>
                      <li className="video-holder">
                        <VideoModal />
                      </li>
                      <li className="video-text">Watch Our Video</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${hero7})` }}
            >
              <div className="container-fluid">
                <div className="slide-content">
                  <div className="slide-title">
                    <h2>
                      From <span>Clean</span> Processing to Secure Storage:
                      Quality You Can Trust
                    </h2>
                  </div>
                  <div className="clearfix"></div>
                  <div className="slide-btns">
                    <ul>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          className="theme-btn-s2"
                          to="/contact"
                        >
                          Get a Free Quote
                        </Link>
                      </li>
                      <li className="video-holder">
                        <VideoModal />
                      </li>
                      <li className="video-text">Watch Our Video</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          ...
        </Swiper>
      </div>
    </section>
  );
};

export default Hero4;
