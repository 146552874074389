import React, { Fragment } from "react";
import HeaderTop from "../../components/HeaderTop/HeaderTop";
import NavbarS2 from "../../components/NavbarS2/NavbarS2";
import Hero4 from "../../components/hero4/Hero4";
import AboutS2 from "../../components/aboutS2/aboutS2";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/umgoldlogo.png";
import ShopPage from "../ShopPage/ShopPage";
import CtaSection from "../../components/CtaSection/CtaSection";
import SkillSection from "../../components/SkillSection/SkillSection";
import SImg from "../../images/skills.png";
import TeamSection from "../../components/TeamSection/TeamSection";
import Testimonial from "../../components/Testimonial/Testimonial";
const HomePage4 = () => {
  return (
    <Fragment>
      <HeaderTop />
      <NavbarS2 hclass={"wpo-site-header wpo-header-style-4"} Logo={Logo} />
      <Hero4 />
      <AboutS2 hclass={"wpo-about-area-s2 section-padding"} />
      <SkillSection hclass={"wpo-skill-section section-padding"} Akimg={SImg} />
      <TeamSection
        hclass={"wpo-team-section section-padding"}
        SectionTitleShow={true}
      />
      <Footer hclass={"wpo-site-footer"} />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage4;
