import img1 from "../images/product/Untitled.png";
import img2 from "../images/product/Untitled(1).png";
import img3 from "../images/product/Untitled(2).png";
import img4 from "../images/product/Без названия.png";
import img5 from "../images/product/Без названия1.png";
import img6 from "../images/product/Без названия(1)2.png";

const product = [
  {
    id: 1,
    proImg: img1,
    singImg: img1,
    title: "Chicken feet",
    subTitle:
      "Chicken feet are a versatile and nutrient-rich ingredient known for their high collagen content, which supports joint health and skin elasticity.",
    slug: "feet",
    description: [
      "No yellow skin",
      "No feather",
      "No bad smell and black pod",
      "No bruise and chemical burns",
      "35 - 45 gr ",
      "12 - 18 sm",
      "Broken bones less than 1%",
      "Moisture less than 2%",
      "IQF at -40",
      "Shelf life 12 - 24 months",
    ],
  },
  {
    id: 2,
    proImg: img2,
    singImg: img2,
    title: "Whole chicken",
    slug: "wholechicken",
    subTitle: " ",
    description: " ",
  },
  {
    id: 3,
    proImg: img3,
    singImg: img3,
    title: "Chicken wings",
    slug: "wings",
    subTitle: " ",
    description: " ",
  },
  {
    id: 4,
    proImg: img4,
    singImg: img4,
    title: "Chicken breast",
    slug: "breast",
    subTitle: " ",
    description: " ",
  },
  {
    id: 5,
    proImg: img5,
    singImg: img5,
    title: "Chicken leg",
    slug: "legs",
    subTitle: " ",
    description: " ",
  },
  {
    id: 6,
    proImg: img6,
    singImg: img6,
    title: "Chicken paws",
    slug: "paws",
    subTitle: " ",
    description: " ",
  },
];

export default product;
