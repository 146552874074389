import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import NavbarS2 from "../../components/NavbarS2/NavbarS2";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import TeamData from "../../api/Team";
import Logo from "../../images/umgoldlogo.png";
import HeaderTop from "../../components/HeaderTop/HeaderTop";

const TeamSinglePage = () => {

  const { slug } = useParams();
  const TeamDetails = TeamData.find((item) => item.slug === slug);

  return (
    <Fragment>
      <HeaderTop />
      <NavbarS2 hclass={"wpo-site-header wpo-header-style-4"} Logo={Logo} />
      <div className="team-pg-area section-padding">
        <div className="container">
          <div className="team-info-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="team-info-img">
                  <img src={TeamDetails.Imga} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="team-info-text">
                  <h2>{TeamDetails.title}</h2>
                  <ul>
                    <li>
                      Position: <span>{TeamDetails.subtitle}</span>
                    </li>

                    <li>
                      Experience:<span>{TeamDetails.experience} Years</span>
                    </li>
                    <li>
                      Address:
                      <span>2 Taraqqiyot street, Tashkent Uzbekistan</span>
                    </li>
                    <li>
                      Email:<span>{TeamDetails.email}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="exprience-area">
            <div className="row">
              <div className="col-lg-12">
                <div className="exprience-wrap">
                  <h2>Personal Experience</h2>
                  <p>{TeamDetails.personalEx}</p>
                </div>
                {/* <div className="education-area ex-wiget">
                  <h2>Education</h2>
                  <ul>
                    <li>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour.
                    </li>
                    <li>If you are going to use a passage of Lorem Ipsum.</li>
                    <li>Very popular during the Renaissance. </li>
                    <li>
                      Many desktop publishing packages and web page editors now.
                    </li>
                  </ul>
                </div>
                <div className="language-area ex-wiget">
                  <h2>Language</h2>
                  <ul>
                    <li>French(fluent), English (fluent), Greek , chinese.</li>
                  </ul>
                </div>
                <div className="wpo-contact-area ex-wiget">
                  <h2>Contact Me</h2>
                  <div className="quote-form">
                    <TeamForm />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer hclass={"wpo-site-footer"} />
      <Scrollbar />
    </Fragment>
  );
};
export default TeamSinglePage;
