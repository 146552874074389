import img1 from "../images/team/IMG_6990.png";
import img2 from "../images/team/1.JPG";
import img3 from "../images/team/IMG_7020.png";
import img4 from "../images/team/Без названия (2).png";

const Team = [
  {
    id: "01",
    title: "Abidshayev Ulugbek",
    subtitle: "Chiev Executive Officer",
    Imga: img1,
    slug: "Ulugbek-Abidshayev",
    email: "ulugbekabidshayev@gmail.com",
    experience: "15",
    personalEx: "No Info",
  },
  {
    id: "02",
    title: "Kurbonov Khojimurod",
    subtitle: "Head of Law",
    Imga: img2,
    slug: "Khojimurod-Kurbonov",
    email: "kurbonovkhojimurod@gmail.com",
    experience: "10",
    personalEx: "No Info",
  },
  {
    id: "03",
    title: "Zaynutdinov Dilmurod",
    subtitle: "Head of Privacy and Security",
    Imga: img3,
    slug: "Dilmurod-Zaytnutdinov",
    email: "zaynutdinovdilmurod@gmail.com",
    experience: "10",
    personalEx: "No Info",
  },
  {
    id: "04",
    title: "Bakhodirov Ilkhomjon",
    subtitle: "Head of Transportation",
    Imga: img4,
    slug: "Ilkhom-Bakhodirov",
    email: "bakhodirovilkhom@gmail.com",
    experience: "10",
    personalEx: "No Info",
  },
];

export default Team;
